import React from "react";
import "./index.scss";

export enum CallToActionType {
    DEFAULT,
    EMPTY
}

export enum CallToActionStyle {
    DEFAULT,
    DARK
}

const CallToAction = ({text, children, action, type, style}:{text?: string, children?: any, action: () => void, type?: CallToActionType, style?: CallToActionStyle}) => {

    const typeClasses = (): string => {
        let result = "callToAction_button";
        
        switch (type) {
            case CallToActionType.EMPTY:
                result = "callToAction_button_empty";
                break;
        }

        return result;
    }

    const buttonStyle = () => {
        let result = typeClasses();

        switch (style) {
            case CallToActionStyle.DARK:
                result += "_dark";
                break;
        }

        return result;
    }
    
    return (
        <button 
            onClick={action}
            className={buttonStyle()}
        >
            {
                text || children
            }
        </button>
    );
};

export default CallToAction;
