
const getCombinedParamsKey = (
    path: string, 
    method: string, 
    body?: any
) => {
    return btoa(`${path}${method}${(body ? JSON.stringify(body) : "")}`);
}

export const request = (
    path: string, 
    method: string, 
    body?: any,
    setCache?: (key: string, value: string, ms: number) => void,
    getCache?: (key: string) => any | null
) => {
    return new Promise((resolve, reject) => {
        let cacheKey = getCombinedParamsKey(path, method, body);
        
        if (getCache!(cacheKey) == null) {
            fetch(path, {
                method,
                ...(body ? {
                    body: JSON.stringify(body)
                }: {}),
                headers: {}
            }).then(
                response => response.json()
            ).then(
                data => {
                    resolve(data);
                    setCache!(cacheKey, data, 30000);
                }
            )   
        } else {
            resolve(getCache!(cacheKey));
        }
    })
}

export const requestNoCache = (
    path: string, 
    method: string, 
    body?: any
) => {
    return new Promise((resolve, reject) => {
        fetch(path, {
            method,
            ...(body ? {
                body: JSON.stringify(body)
            }: {}),
            headers: {}
        }).then(
            response => response.json()
        ).then(
            data => {
                resolve(data);
            }
        )
    })
}
