import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import { AppContext } from "../../../context";
import VideoTool from "../../video";
import { useTranslation } from "react-i18next";


const ContactSection = () => {
    const { t } = useTranslation();
    const { clientEndpoints } = useContext(AppContext);

    const [name, setName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [phone, setPhone] = useState<number | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [attemptingSend, setAttemptingSend] = useState<boolean>(false);

    const settingValue = (event: any, setter: (val: any) => void) => {
        setter(event.target.value);
    }

    const sendContact = () => {
        if (!attemptingSend) {
            if (name && email && phone && message) {
                setAttemptingSend(true);
                clientEndpoints?.postCommunication({
                    name,
                    email,
                    telephone: phone,
                    message
                }).then(
                    data => {
                        if (data && data.response) {
                            setAttemptingSend(false);
                            setMessageSent(true);
                            setTimeout(() => {
                                setMessageSent(false);
                            }, 5000);
                            setName(null);
                            setEmail(null);
                            setPhone(null);
                            setMessage(null);
                        }
                    }
                ).catch(
                    error => {
                        setAttemptingSend(false);
                    }
                )
            }
        }
    }

    return (
        <div className="contact_box">
            <div className="contact_message">
                <div className="contact_message_content">
                    <span
                        className="contact_message_title"
                    >
                        {t("Home.Contact.Words.1")}<span>{t("Home.Contact.Words.2")}</span>{t("Home.Contact.Words.3")}<span>{t("Home.Contact.Words.4")}</span>
                    </span>
                    <VideoTool 
                        posterSrc="https://assets.vitessestack.com/website/thumbnail.png"
                        videoSrc="https://assets.vitessestack.com/website/product-manager.mp4"
                    />
                    <div
                        className="contact_info"
                    >
                        <div>
                            <span>
                                <span></span>
                            </span>
                            <span>{t("Home.Contact.Points.1")}</span>
                        </div>
                        <div>
                            <span>
                                <span></span>
                            </span>
                            <span>{t("Home.Contact.Points.2")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact_form">
                <div className="contact_form_title">{t("Home.Contact.Form.Title")}</div>
                <form className="contact_form_form" action="none">
                    <input 
                        type="text" 
                        placeholder={t("Home.Contact.Form.Name")}
                        onChange={(event) => settingValue(event, setName)}
                        value={name || ""}
                    />
                    <input 
                        type="text" 
                        placeholder={t("Home.Contact.Form.Email")}
                        onChange={(event) => settingValue(event, setEmail)}
                        value={email || ""}
                    />
                    <input 
                        type="number" 
                        placeholder={t("Home.Contact.Form.Phone")}
                        onChange={(event) => settingValue(event, setPhone)}
                        value={phone || ""}
                    />
                    <textarea 
                        placeholder={t("Home.Contact.Form.Message")}
                        onChange={(event) => settingValue(event, setMessage)}
                        value={message || ""}
                    ></textarea>
                </form>
                <button
                    className={(attemptingSend || messageSent) ? "contact_form_submit_sent" : "contact_form_submit"}
                    onClick={() => sendContact()}
                >
                    {
                        attemptingSend ? t("Home.Contact.Form.Send.Sending") : (messageSent ? t("Home.Contact.Form.Send.Sent") : t("Home.Contact.Form.Send.General"))
                    }
                </button>
            </div>
            
        </div>
    );
};

export default ContactSection;
