import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";

import ContactSection from '../../components/sections/contact';
import HeaderSection from "../../components/sections/header";
import FooterSection from "../../components/sections/footer";
import { SERVICES_LIST_CONTENT } from "../../data/services";
import { MENU_PAGE_SERVICE_ID } from "../../data/menu";
import { ServiceItem } from "./common";

import WhatIsServicesSection from "./sections/what_is";
import PhasesServicesSection from "./sections/phases";
import DeliverablesServicesSection from "./sections/deliverables";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context";
import { useTranslation } from "react-i18next";


function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const ServicesPage = () => {
    const { t } = useTranslation();
    const { clientEndpoints } = useContext(AppContext);

    const query = useQuery();
    const navigate = useNavigate();
    const [servicesData, setServicesData] = useState<ServiceItem[]>([]);
    const [mappedServicesData, setMappedServicesData] = useState<Map<string, ServiceItem>>(new Map());
    const contactSectionRef = useRef<HTMLButtonElement>(null);
    const [selectedService, setSelectedService] = useState<string>("");

    const sectionDetailRef = useRef<HTMLElement>(null);

    const setMappedServicesMethod = (servicesData: ServiceItem[]) => {
        const mappedServices = new Map();
        servicesData.forEach(service => {
            mappedServices.set(service.id, service);
        })

        setMappedServicesData(mappedServices);
    };


    useEffect(() => {
        /**
         * Setting services information from API
        */
        clientEndpoints?.getServicesInformation().then(
            data => {
                let servicesContent: ServiceItem[] = data.content;
                setMappedServicesMethod((servicesContent as ServiceItem[]));
                setSelectedService((servicesContent[0] as ServiceItem).id);
                setServicesData(servicesContent);
            }
        )
    }, []);

    useEffect(() => {
        if (mappedServicesData) {
            const service = query.get("service");
            setSelectedService(service as string);
            selectService(service as string);
        }
    }, [query]);

    const selectService = (id: string) => {
        setSelectedService(id);
        sectionDetailRef.current?.scrollIntoView({ behavior: 'smooth', inline: "start"});
    }

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <HeaderSection 
                onContactClick={() => contactSectionRef.current?.scrollIntoView()}
                menuSelectedOption={MENU_PAGE_SERVICE_ID}
            />
            <main>
                <section className="services">
                    <span className="services_title">{t("Services.Title")}</span>
                    <div className="services_list">
                        {
                            servicesData.map(service => (
                                <div 
                                    className={selectedService == service.id ? "service_selected" : "service"}
                                    onClick={() => navigate({
                                        pathname: "",
                                        search: createSearchParams({
                                            service: service.id
                                        }).toString()
                                    })}
                                >
                                    <span className="service_name">{service.title}</span>
                                    <div className="service_image">
                                        <img src={service.icon.empty} alt="service_icon_image_empty" />
                                        <img src={service.icon.full} alt="service_icon_image_full" />
                                        <img src={service.icon.selected} alt="service_icon_image_selected" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <section className="service_details" ref={sectionDetailRef}>
                    {
                        mappedServicesData.get(selectedService) != undefined ? (
                            <>
                                <WhatIsServicesSection data={mappedServicesData.get(selectedService) as ServiceItem}/>
                                <PhasesServicesSection data={mappedServicesData.get(selectedService) as ServiceItem}/>
                                <DeliverablesServicesSection data={mappedServicesData.get(selectedService) as ServiceItem}/>
                            </>
                        ) : null
                    }
                </section>
            </main>
            <section className='contact_section' ref={contactSectionRef}>
                <ContactSection />
            </section>
            <FooterSection />
        </>
    );
};

export default ServicesPage;
