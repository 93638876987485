import React from "react";
import "./index.scss";

import Block, { BlockPosition } from "../block";


export enum TextStyle {
    DEFAULT = "DEFAULT",
    TITLE = "TITLE",
    SUBTITLE = "SUBTITLE",
    HEAD_1 = "HEAD_1",
    HEAD_2 = "HEAD_2",
    HEAD_3 = "HEAD_3",
    QUOTE = "QUOTE"
}

const TextBlock = ({ text, textStyle, position }: { text: string, textStyle?: TextStyle, position?: BlockPosition }) => {

    const getTextStyleClass = () => {
        let result;

        switch (textStyle) {
            case TextStyle.DEFAULT:
                result = "default";
                break;
            case TextStyle.TITLE:
                result = "title";
                break;
            case TextStyle.SUBTITLE:
                result = "subtitle";
                break;
            case TextStyle.HEAD_1:
                result = "head-1";
                break;
            case TextStyle.HEAD_2:
                result = "head-2";
                break;
            case TextStyle.HEAD_3:
                result = "head-3";
                break;
            case TextStyle.QUOTE:
                result = "quote";
                break;
            default:
                result = "default";
                break;
        }

        return result;
    }
    
    return (
        <Block position={position}>
            <div
                className="text-box"
            >
                <span className={getTextStyleClass()}>{text}</span>
            </div>
        </Block>
    );
}

export default TextBlock;
