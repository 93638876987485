import React, { useEffect, useState } from "react";
import "./index.scss";
import Menu from "../../menu";
import { MenuStyle } from "../../menu/types";
import CallToAction, { CallToActionStyle } from "../../buttons/callToAction";
import { DEFAULT_LANGUAGE, HEADER_FIRST_SCROLL_LIMIT } from "../../../constants";
import Hamburguer, { HamburguerStyle } from "../../buttons/hamburguer";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderStyle } from "./types";
import { useTranslation } from "react-i18next";


const HeaderSection = ({onContactClick, menuSelectedOption, style, keepStyle}:{onContactClick?: () => void, menuSelectedOption?: string, style?: HeaderStyle, keepStyle?: boolean}) => {
    const { lang } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [headerClass, setHeaderClass] = useState<string>("header");
    const [logo, setLogo] = useState<string>("/logo/white_text.png");
    const [menuStyle, setMenuStyle] = useState<MenuStyle>(MenuStyle.DEFAULT);
    const [ctaStyle, setCtaStyle] = useState<CallToActionStyle>(CallToActionStyle.DEFAULT);
    const [hamStyle, setHamStyle] = useState<HamburguerStyle>(HamburguerStyle.DEFAULT);

    const lightConfiguration = () => {
        setHeaderClass("header");
        setLogo("/logo/white_text.png");
        setMenuStyle(MenuStyle.DEFAULT);
        setCtaStyle(CallToActionStyle.DEFAULT);
        setHamStyle(HamburguerStyle.DEFAULT);
    }

    const darkConfiguration = () => {
        setHeaderClass("header_after");
        setLogo("/logo/grey_text.png");
        setMenuStyle(MenuStyle.DARK);
        setCtaStyle(CallToActionStyle.DARK);
        setHamStyle(HamburguerStyle.DARK);
    }

    const scrollingManager = () => {
        if (window.scrollY > HEADER_FIRST_SCROLL_LIMIT) {
            // By default header switches style on scroll.
            if (!keepStyle) {
                if (style && style == HeaderStyle.DARK) {
                    lightConfiguration();
                } else {
                    darkConfiguration();
                }
            }
        } else {
            if (style && style == HeaderStyle.DARK) {
                darkConfiguration();
            } else {
                lightConfiguration();
            }
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollingManager)
    }, []);

    useEffect(() => {
        if (style && style == HeaderStyle.DARK) {
            darkConfiguration();
        } else {
            lightConfiguration();
        }
    }, []);


    return (
        <header
            className={headerClass}
        >
            <section className="header_box">
                <section className='logo_section'>
                    <img src={logo} alt="logo_text" onClick={() => navigate(`/${(lang as string).toLowerCase() || DEFAULT_LANGUAGE}`)}/>
                </section>
                <section className='menu_section'>
                    <div className='menu_menu'>
                    <Menu style={menuStyle} selectedOption={menuSelectedOption}/>
                    </div>
                    {
                        onContactClick && (
                            <>
                                <div className='menu_callToAction'>
                                    <CallToAction text={t("Header.ContactButton")} action={(() => onContactClick())} style={ctaStyle}/>
                                </div>
                                <div className='menu_callToAction_icon'>
                                    <CallToAction action={(() => onContactClick())} style={ctaStyle}>
                                        <img src="/icons/various/email.png" className="callToAction_icon" alt="call_to_action_icon" />
                                    </CallToAction>
                                </div>
                            </>
                        )
                    }
                    <div className='menu_menu_hamburguer'>
                    <div className='hamburguer_box'>
                        <Hamburguer style={hamStyle}/>
                    </div>
                    </div>
                </section>
                </section>
        </header>
    );
};

export default HeaderSection;
