import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";


const Selector = ({options, setOption}:{options: string[], setOption?: (option: number) => void}) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<number|null>(null);
    const [extendedOptions, setExtendedOptions] = useState<boolean>(false);

    const selectOption = (option: number) => {
        setExtendedOptions(false);
        setSelectedOption(option);
        if (setOption) {
            setOption(option);
        }
    }

    return (
        <div 
            className="selector"
        >
            <div className="selector_selected">
                <span className="selected" onClick={() => setExtendedOptions(current => !current)}>
                    {
                        selectedOption != null ? options[selectedOption!] : t("Components.Selector.Default")
                    }
                </span>
                {
                    extendedOptions && (
                        <div 
                            className="selector_options"
                        >
                            <button 
                                className="option"
                            >
                                {t("Components.Selector.Default")}
                            </button>
                            {
                                options.map((option, index) => (
                                    <button 
                                        className="option"
                                        onClick={() => selectOption(index)}
                                    >
                                        {option}
                                    </button>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <button
                className="selector_extender"
                onClick={() => setExtendedOptions(current => !current)}
            >
                <div className={extendedOptions ? "extender_button_extended" : "extender_button"}>
                    <img src="/icons/various/arrowdown.png" alt="arrowdown_image" />
                    <img src="/icons/various/arrowup.png" alt="arrowup_image" />
                </div>
            </button>
        </div>
    );
};

export default Selector;
