import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppContext } from "../../context";
import ScreenMenu from "../../components/screen-menu";
import { getClientEndpoints } from "../../client";
import { CACHE_TIMEOUT_MS, DEFAULT_LANGUAGE } from "../../constants";
import { useTranslation } from "react-i18next";


const ConfigurationPage = () => {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const [showScreenMenu, setShowScreenMenu] = useState<boolean>(false);
    const [cache, setCache] = useState<Map<string, string>>(new Map());
    
    const getCacheValue = (key: string): any | null => {
      let result = null;
      if (cache.has(key)) {
        result = JSON.parse(cache.get(key)!);
      }
      return result;
    }
  
    const removeCache = (key: string) => {
      setCache(current => {
        const newMap = new Map(current);
        newMap.delete(key);
        return newMap;
      })
    }
  
    const setCacheValue = (key: string, value: string) => {
      setCache(current => {
        const newMap = new Map(current);
        newMap.set(key, JSON.stringify(value));
  
        setTimeout(() => {
          removeCache(key);
        }, CACHE_TIMEOUT_MS);
        
        return newMap;
      })
    }
  
    const clientEndpoints = getClientEndpoints(
      setCacheValue,
      getCacheValue,
      lang?.toLowerCase()
    )

    useEffect(() => {
      i18n.changeLanguage(lang?.toLowerCase() || DEFAULT_LANGUAGE );
    }, []);

    return (
        <AppContext.Provider value={
            {
                showScreenMenu, 
                setShowScreenMenu, 
                getCacheValue, 
                setCacheValue,
                clientEndpoints
            }
        }>
            {
                showScreenMenu && <ScreenMenu />
            }
            <Outlet />
        </AppContext.Provider>
    );
}

export default ConfigurationPage;
