import React from "react";
import "./index.scss";

import Block, { BlockPosition } from "../block";


const ImageBlock = ({ source, name, size, position }: { source: string, name: string, size: number, position?: BlockPosition }) => {
    return (
        <>
            <Block position={position}>
                <div
                    className="image-box"
                    style={{width: size}}
                >
                    <img src={source} alt={`{name}_image`} />
                    <span className="image-name">{name}</span>
                </div>
            </Block>
        </>
    );
}

export default ImageBlock;
