
export const FOOTER_MENU = (data: any, t: (path: string) => string, lang: string) => [
    {
        title: t("Footer.Menu.1.Title"),
        links: [
            {
                name: t("Footer.Menu.1.Links.Services"),
                link: {
                    value: `/${lang}/servicios`
                }
            },
            {
                name: t("Footer.Menu.1.Links.Prices"),
                link: {
                    value: `/${lang}/precios`
                }
            },
            {
                name: t("Footer.Menu.1.Links.Careers"),
                link: {
                    value: `/${lang}/carreras`
                }
            },
            {
                name: t("Footer.Menu.1.Links.AboutUs"),
                link: {
                    value: `/${lang}/nosotros`
                }
            }
        ]
    },
    {
        title: t("Footer.Menu.2.Title"),
        links: [
            {
                name: t("Footer.Menu.2.Links.ScheduleCall"),
                link: {
                    value: data.appointment,
                    target: "_blank"
                }
            },
            {
                name: t("Footer.Menu.2.Links.EmailProduct"),
                link: {
                    value:  `mailto:${data.productEmail}`
                }
            }
        ]
    },
    {
        title: t("Footer.Menu.3.Title"),
        links: [
            {
                name: "x",
                link: {
                    value: "https://twitter.com/vitessestack",
                    target: "_blank"
                },
                icon: {
                    default: "/icons/socials/x.png",
                    selected: "/icons/socials/x_dark.png"
                }
            },
            {
                name: "youtube",
                link: {
                    value: "https://www.youtube.com/@Vitessestack",
                    target: "_blank"
                },
                icon: {
                    default: "/icons/socials/youtube.png",
                    selected: "/icons/socials/youtube_dark.png"
                }
            },
            {
                name: "instagram",
                link: {
                    value: "https://www.instagram.com/vitessestack",
                    target: "_blank"
                },
                icon: {
                    default: "/icons/socials/instagram.png",
                    selected: "/icons/socials/instagram_dark.png"
                }
            },
            {
                name: "tiktok",
                link: {
                    value: "https://www.tiktok.com/@vitessestack",
                    target: "_blank"
                },
                icon: {
                    default: "/icons/socials/tiktok.png",
                    selected: "/icons/socials/tiktok_dark.png"
                }
            }
        ]
    }
]
