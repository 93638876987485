import React, { useContext } from "react";
import "./index.scss";
import CallToAction, { CallToActionType } from "../../buttons/callToAction";
import { AppContext } from "../../../context";
import { useTranslation } from "react-i18next";


const VideoSection = () => {
  const { t } = useTranslation();
  const { clientEndpoints } = useContext(AppContext);

  const openAppointmentSchedule = () => {
    clientEndpoints?.getAppointmentSchedule().then(
      data => {
        const endpoint: string = data.content[0].link;
        window.open(endpoint, '_blank');
      }
    )
  }

  const openBrochure = () => {
    clientEndpoints?.getBrochureLink().then(
      data => {
        const endpoint: string = data.content[0].link;
        window.open(endpoint, '_blank');
      }
    )
  }

  return (
      <div
          className='video_box'
        >
          <video 
            src="https://assets.vitessestack.com/website/promotional_muted.mp4" 
            className='video'
            loop
            autoPlay
            muted 
            playsInline
          ></video>
          <div
            className='video_overlay'
          >
            <div
              className='overlay_content'
            >
              <div
                className='overlay_texts'
              >
                <span className='overlay_slogan'>{t("Home.Video.Slogan")}</span>
                <span className='overlay_message'>
                  <span>{t("Home.Video.Title.1")} </span>
                  <span>{t("Home.Video.Title.2")}</span>
                </span>
              </div>
              <div className="overlay_buttons">
                <div className='overlay_callToAction'>
                  <CallToAction text={t("Home.Video.Buttons.Appointment")} action={openAppointmentSchedule} type={CallToActionType.EMPTY}/>
                </div>
                <div className='overlay_callToAction'>
                  <CallToAction text={t("Home.Video.Buttons.Brochure")} action={openBrochure} type={CallToActionType.EMPTY}/>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default VideoSection;
