import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import HeaderSection from "../../components/sections/header";
import { MENU_PAGE_PRICES_ID } from "../../data/menu";
import ContactSection from "../../components/sections/contact";
import FooterSection from "../../components/sections/footer";
import { Estimation, PriceParam, PriceType } from "./types";
import Dragger from "../../components/buttons/dragger";
import { DEFAULT_PRICE_ESTIMATE } from "../../data/prices";
import Selector from "../../components/buttons/selector";
import { getShortlengthNumber } from "../../utils";
import { AppContext } from "../../context";
import { useTranslation } from "react-i18next";


const PricingPage = () => {
    const { t } = useTranslation();
    const { clientEndpoints } = useContext(AppContext);

    const contactSectionRef = useRef<HTMLButtonElement>(null);

    const [estimatedPrice, setEstimatedPrice] = useState<PriceType | null>(null);
    const [params, setParams] = useState<PriceParam[]>([]);
    const [mappedParamsValues, setMappedParamsValues] = useState<Map<string, number|null>>(new Map());

    const selectOption = (param: PriceParam) => {
        return (option: number) => {
            setMappedParamsValues(current => {
                const newMap = new Map(current);
                newMap.set(param.id, option);
                return newMap;
            });
        }
    }

    const getTool = (param: PriceParam) => {
        let result = null;

        switch (param.options.tool) {
            case "dragger":
                result = (
                    <Dragger 
                        steps={param.options.values.map(value => value.display)} 
                        examples={param.options.values.map(value => value.example || null)}
                        setStep={selectOption(param)}
                    />
                )
                break;
            case "selector":
                result = <Selector options={param.options.values.map(value => value.display)} setOption={selectOption(param)}/>
                break;
        }

        return result;
    }

    const settingCostEstimate = () => {
        const valuesSet = new Set(mappedParamsValues.values());

        if (valuesSet.size > 0 && !valuesSet.has(null) && mappedParamsValues.size == params.length) {
            // Send params vals to API for cost estimate
            let paramsValues = Object.fromEntries(
                params.map(
                    param => {
                        return [
                            param.options.key,
                            param.options.values.at(
                                mappedParamsValues.get(param.id) as number
                            )?.value
                        ]
                    }
                )
            )
            
            clientEndpoints?.getEstimation(paramsValues).then(
                data => {
                    if (data && data.response) {
                        let estimation: Estimation = data.response;
                        
                        setEstimatedPrice({
                            concept: {
                                title: DEFAULT_PRICE_ESTIMATE(t).concept.title,
                                description: params.map(
                                    param => {
                                        return `${param.title[0].toUpperCase()}${param.title.slice(1).toLowerCase()}: ${
                                            param.options.values.at(
                                                mappedParamsValues.get(param.id) as number
                                            )?.display.toLowerCase()
                                        }`
                                    }
                                ).join("  •  ")
                            },
                            value: {
                                min: estimation.min,
                                max: estimation.max
                            },
                            currency: DEFAULT_PRICE_ESTIMATE(t).currency
                        });
                    }
                }
            )
        }
    }

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        /**
         * Setting prices information from API
        */
        clientEndpoints?.getPricesInformation().then(
        data => {
            let content: PriceParam[] = data.content;
            setParams(content);
        }
       )
    }, []);

    // useEffect(() => {
    //     setMappedParamsValues(current => {
    //         const newMap = new Map();
    //         params.forEach(param => {
    //             newMap.set(param.id, null);
    //         });
    //         return newMap;
    //     });
    // }, [params]);

    useEffect(() => {
        settingCostEstimate();
    }, [mappedParamsValues]);

    return (
        <>
            <HeaderSection
                onContactClick={() => contactSectionRef.current?.scrollIntoView()}
                menuSelectedOption={MENU_PAGE_PRICES_ID}
            />
            <main className="price_main">
                <span className="prices_title">{t("Prices.Title")}</span>
                <span className="prices_info">
                    <span className="prices_info_description">
                        {t("Prices.Subtitle")}
                    </span>
                    <div className="prices_info_params">
                    {
                        params.map(param => (
                            <span className="prices_info_param_title">
                                <img src="/icons/various/play.png" alt="" />
                                <span>{param.title}</span>
                            </span>
                        ))
                    }
                    </div>
                </span>
                <section className="prices_interactive">
                    <div className="prices_interactive_params">
                        <div className="param">
                            {
                                params.map(param => (
                                    <>
                                        <span className="question">
                                            {
                                                param.question.map(text => (
                                                    <span className={text.type}>{text.value} </span>
                                                ))
                                            }
                                        </span>
                                        <div className="tool">
                                        {
                                            getTool(param)
                                        }
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <div className="prices_interactive_results">
                        <span className="results_title">{t("Prices.Concept.Title")}</span>
                        <div className="results_value">
                            {
                                estimatedPrice?.value ? (
                                    <span>{getShortlengthNumber(estimatedPrice?.value.min)} - {getShortlengthNumber(estimatedPrice?.value.max)}</span>
                                ) : <span>-</span>
                            }
                            <span>{estimatedPrice?.currency}</span>
                        </div>
                        <span className="results_information">
                            {estimatedPrice?.concept.description || t("Prices.Concept.Description")}
                        </span>
                        <span className="results_disclaimer">*{t("Prices.Disclaimer")}</span>
                    </div>
                </section>
            </main>
            <section className='contact_section' ref={contactSectionRef}>
                <ContactSection />
            </section>
            <FooterSection />
        </>
    );
};

export default PricingPage;
