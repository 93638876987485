import React, { useContext } from "react";
import "./index.scss";
import { MENU } from "../../data/menu";
import { MenuOption } from "../menu/types";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../constants";


const ScreenMenu = () => {
    const { t } = useTranslation();
    const { setShowScreenMenu } = useContext(AppContext);
    const navigate = useNavigate();

    const { lang } = useParams();

    const selectOption = (option: MenuOption) => {
        navigate(option.link)
        setShowScreenMenu(false);
    }

    return (
        <menu className="screen_menu">
            {
                (MENU(t, (lang as string).toLowerCase() || DEFAULT_LANGUAGE) as MenuOption[]).map(option => (
                    <button
                        onClick={() => selectOption(option)}
                        className="screen_menu_option"
                    >
                        <span className="option">{option.name}</span>
                    </button>
                ))
            }
        </menu>
    );
};

export default ScreenMenu;
