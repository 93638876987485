import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { FOOTER_MENU } from "../../../data/footer";
import { AppContext } from "../../../context";
import { APIRequestResponse } from "../../../client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DEFAULT_LANGUAGE } from "../../../constants";


type FooterItem = {
    name: string;
    link: {
        value: string;
        target?: string;
    };
    icon?: {
        default: string;
        selected?: string;
    };
}


const FooterSection = () => {
    const { lang } = useParams();
    const { t } = useTranslation();
    const [footerData, setFooterData] = useState<any[]>([]);
    const [appointmentLink, setAppointmentLink] = useState<string>("");
    const [productEmail, setProductEmail] = useState<string>("");

    const { clientEndpoints } = useContext(AppContext);

    const getFirstElement = (data: APIRequestResponse) => {
        return data.content && data.content.length > 0 ? data.content[0] : {};
    }

    useEffect(() => {
        clientEndpoints?.getAppointmentSchedule().then(
            data => {
                const endpoint: string = getFirstElement(data).link;
                setAppointmentLink(endpoint);
            }
        )

        clientEndpoints?.getProductEmail().then(
            data => {
                const endpoint: string = getFirstElement(data).email;
                setProductEmail(endpoint);
            }
        )
    }, []);

    useEffect(() => {
        setFooterData(FOOTER_MENU({
            appointment: appointmentLink,
            productEmail: productEmail
        }, t, (lang as string).toLowerCase() || DEFAULT_LANGUAGE));
    }, [appointmentLink, productEmail]);

    return (
        <footer>
            <section className="footer_menu">
                <div className="footer_menu_logo">
                    <img src="/logo.png" alt="" />
                </div>
                <div className="footer_menu_link">
                    {
                        footerData.map(section => (
                            <div className="footer_menu_link_section">
                                <div
                                    className="footer_menu_link_section_title"
                                >{section.title}</div>
                                <div
                                    className="footer_menu_link_section_links"
                                >
                                {
                                    section.links.map((link: FooterItem) => (
                                        <a
                                            href={link.link.value}
                                            target={link.link.target || ""}
                                        >
                                            {
                                                link.icon ? (
                                                    <div className="social_image">
                                                        <img src={link.icon.selected} alt="social_link_image" />
                                                        <img src={link.icon.default} alt="social_link_image" />
                                                    </div>
                                                ) : link.name
                                            }
                                        </a>
                                    ))
                                }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
            <section
                className="footer_information"
            >
                <div className="footer_information_separation"></div>
                <div className="footer_information_content">
                    <span>{t("Footer.Copyright")}</span>
                    <div className="footer_information_content_links">
                        {/* <button>Politica de privacidad</button>
                        <span>|</span>
                        <button>Terminos de servicio</button> */}
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default FooterSection;
