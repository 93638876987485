
export enum BlogDataType {
    LIST = "LIST",
    CONTENT = "CONTENT"
}

export type ArticleItem = {
    id: string;
    title: string;
    subtitle: string;
    description: string;
    topics: string[];
}

export type BlogData = {
    type: string;
    path: string[];
    data: any;
    size: number;
    pages: number;
}

export enum BlogSearchParams {
    ARTICLE_NAME = "articleName",
    ARTICLE_ID = "articleId",
    ARTICLE_PATH = "articlePath",
    PUBLICATION_START = "pubStart",
    PUBLICATION_END = "pubEnd",
    TOPICS_TAGS = "topTags",
    PAGE = "page"
}

export const FilterBodyMapping = new Map<string, string>([
    [BlogSearchParams.ARTICLE_ID, "article_id"],
    [BlogSearchParams.ARTICLE_NAME, "article_name"],
    [BlogSearchParams.TOPICS_TAGS, "topics"]
])

export const BLOG_DATA_TYPE_KEY = "data_type";
