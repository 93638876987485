import React, { useEffect, useRef, useState } from "react";
import "./index.scss";


const Dragger = ({steps, examples, setStep}:{steps?: string[], examples?: (string | null)[]; setStep?: (step: number) => void}) => {
    const [marketPercentage, setMarkerPercentage] = useState<number>(0);

    const [dragging, setDragging] = useState<boolean>(false);
    const [selectedStep, setSelectedStep] = useState<number|null>(null);
    const [startedMoving, setStartedMoving] = useState<boolean>(false);
    const [timeoutID, setTimeoutID] = useState<any>(null);

    const getElementRect = (id: string): DOMRect | undefined => {
        const pos = document.getElementById(id);
        const rect = pos?.getBoundingClientRect();
        return rect;
    }

    const getBarRect = (): DOMRect | undefined => {
        return getElementRect("dragger-bar");
    }

    const getMarkerRect = (): DOMRect | undefined => {
        return getElementRect("dragger-marker");
    }

    const setPercentageWithXPosition = (pageX: number) => {
        const barRect = getBarRect();
        const markerRect = getMarkerRect();

        if (barRect && markerRect) {
            const currentPositionX = pageX-barRect?.x;

            if (currentPositionX >= 0 && currentPositionX <= barRect?.width) {
                setMarkerPercentage((100/barRect.width)*currentPositionX);
            }
        }
        
    }

    const onMouseMove = React.useCallback((e: any) => {
        setPercentageWithXPosition(e.pageX);
        if (!dragging) return;
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const onMouseUp = React.useCallback((e: any) => {
        setDragging(false);
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const settingStepSelected = () => {
        let result = 0;
        if (steps && steps.length) {
            result = Math.round((steps.length-1)/1*(marketPercentage/100));
        }
        if (setStep && result != selectedStep) {
            setStep(result);
        }
        setSelectedStep(result);
    }

    const initStartedMoving = () => {
        setStartedMoving(dragging || startedMoving);
        const currentTimeout = setTimeout(() => {
            setStartedMoving(dragging);
        }, 5000);

        setTimeoutID((current: any) => {
            if (current) {
                clearTimeout(current);
            }
            return currentTimeout;
        });
    }

    useEffect(() => {
        settingStepSelected();
    }, [marketPercentage]);

    useEffect(() => {
        settingStepSelected();
    }, []);


    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", onMouseMove)
            document.addEventListener("mouseup", onMouseUp)
        } else {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseUp)
        }
        initStartedMoving();
    }, [dragging]);

    return (
        <span className="dragger">
            <span
                id="dragger-bar"
                className="bar"
            >
                {
                    steps && steps?.length > 0 && (
                        <div className="labels">
                            {
                                steps.map((step, index) => (
                                    <div className={ index == selectedStep ? "label_selected" : "label"}>
                                        <span>{step}</span>
                                        {
                                            examples && examples[index] && startedMoving ? (
                                                <div className={ index == selectedStep ? "label_tooltip" : "label_tooltip_hidden"}>
                                                    <div className="label_tooltip_container">
                                                        <div className="label_tooltip_point"></div>
                                                        <div className="label_tooltip_text">
                                                            <div className="label_tooltip_text_value">{examples[index]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <span
                    id="dragger-marker"
                    className="marker"
                    style={{
                        left: `${marketPercentage}%`
                    }}
                    onMouseDown={() => setDragging(true)}
                    onTouchMove={(e:any) => setPercentageWithXPosition(e.touches[0].pageX)}

                >
                </span>
            </span>
        </span>
    );
}

export default Dragger;
