
export type MenuOption = {
    id: string;
    name: string;
    link: string;
    items?: any[];
}

export enum MenuStyle {
    DEFAULT,
    DARK
}
