import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import HeaderSection from "../../components/sections/header";
import { MENU_PAGE_BLOG_ID } from "../../data/menu";
import ContactSection from "../../components/sections/contact";
import FooterSection from "../../components/sections/footer";
import { AppContext } from "../../context";
import { getBlocksFromPayload } from "../../components/blog";

import { useSearchParams } from "react-router-dom";
import { ArticleItem, BLOG_DATA_TYPE_KEY, BlogData, BlogDataType, BlogSearchParams, FilterBodyMapping } from "./data";
import Loading from "../../components/loading";
import { useTranslation } from "react-i18next";


const BlogPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [searchInput, setSearchInput] = useState<string>("");

    const contactSectionRef = useRef<HTMLButtonElement>(null);
    const { clientEndpoints } = useContext(AppContext);

    const [blogData, setBlogData] = useState<BlogData | null>(null);
    const [page, setPage] = useState<number>(0);

    const [loadingBlogData, setLoadingBlogData] = useState<boolean>(false);


    const getFilters = () => {
        let filtersMap: Map<string, any> = new Map();

        Object.values(BlogSearchParams).filter(
            param => searchParams.get(param)
        ).forEach(
            param => filtersMap.set(param, searchParams.get(param))
        )

        return Object.fromEntries(filtersMap.entries());
    }

    const getFiltersBody = (filters: any, blog_data_type: BlogDataType) => {
        let result = Object.fromEntries(
            Object.keys(filters).map(
                filterKey => [FilterBodyMapping.get(filterKey), filters[filterKey]]
            )
        )

        result[BLOG_DATA_TYPE_KEY] = blog_data_type;
        result[BlogSearchParams.PAGE] = page;

        return result;
    }

    const getBlogsData = (blogDataType: BlogDataType) => {
        let filters = getFilters();
        setLoadingBlogData(true);

        clientEndpoints?.getBlogs(
            getFiltersBody(filters, blogDataType)
        ).then((data: any) => {
            setBlogData(data);
        }).finally(() => {
            setLoadingBlogData(false);
        })
    }

    const chooseArticleItem = (item: ArticleItem) => {
        setPage(0);
        setSearchParams(Object.fromEntries([[BlogSearchParams.ARTICLE_ID, item.id]]));
    }

    const getSearchTopics = () => {
        let topics = searchInput.match(/::\w+/g);
        let textWithoutTopics = searchInput.replace(/::\w+/g, '');

        return [topics?.map(topic => topic.replace(/::/g, '')), textWithoutTopics]
    }

    const searchQuery = () => {
        let [topics, text] = getSearchTopics();
        let blogSearchParams = new Map();

        blogSearchParams.set(BlogSearchParams.ARTICLE_NAME, text);
        blogSearchParams.set(BlogSearchParams.TOPICS_TAGS, ((topics as string[]) || []).join(","));

        setSearchParams(Object.fromEntries(blogSearchParams));
    }

    const handleEnterKeySearch = (event: any) => {
        if (event.key === 'Enter') {
            searchQuery();
          }
    }

    const parseArticleList = (list: ArticleItem[], pages: number) => {
        return (
            <div className="articles-list-box">
                <div className="articles-list">
                    {
                        list.map(article => (
                            <button
                                className="article-item"
                                onClick={() => chooseArticleItem(article)}
                            >
                                <span className="article-title">{article.title}</span>
                                <span className="article-subtitle">{article.subtitle}</span>
                                <span className="article-description">{article.description}</span>
                                <div className="article-topics">
                                    {
                                        article.topics.map(topic => (
                                            <span>{topic}</span>
                                        ))
                                    }
                                </div>
                            </button>
                        ))
                    }
                </div>
                <div className="articles-pagination">
                    {
                        Array(pages).fill(null).map((value, index) => (
                            <button 
                                className={page == index ? "page-number-selected" : "page-number"}
                                onClick={() => setPage(index)}
                            >
                                {index+1}
                            </button>
                        ))
                    }
                </div>
            </div>
        );
    }

    const parseArticlePayload = () => {
        return (
            <div
                className="article-payload"
            >
                {getBlocksFromPayload(blogData!.data)}
            </div>
        );
    }

    const parseBlogData = () => {
        let result;

        switch (blogData!.type) {
            case BlogDataType.LIST:
                result = parseArticleList(blogData!.data, blogData!.pages);
                break;
            case BlogDataType.CONTENT:
                result = parseArticlePayload();
                break;
        }

        return result;
    }

    useEffect(() => {
        getBlogsData(searchParams.get(BlogSearchParams.ARTICLE_ID) != null ? BlogDataType.CONTENT : BlogDataType.LIST);
    }, [searchParams]);

    useEffect(() => {
        setSearchParams(current => {
            searchParams.set(BlogSearchParams.PAGE, page.toString());
            return current;
        });
    }, [page]);

    return (
        <>
            <HeaderSection
                onContactClick={() => contactSectionRef.current?.scrollIntoView()}
                menuSelectedOption={MENU_PAGE_BLOG_ID}
            />
            <main
                className="content-main"
            >
                <div
                    className="content-space"
                >
                    <div
                        className="content-articles"
                    >
                        <div
                            className="content-search"
                        >
                            <input 
                                type="text"
                                value={searchInput}
                                onChange={(event) => setSearchInput(event.target.value)}
                                onKeyDown={(event) => handleEnterKeySearch(event)}
                                placeholder={t("Blog.Search.Placeholder")}
                            />
                            <button className="content-search-image" onClick={() => searchQuery()}>
                                <img src="/icons/various/email.png" alt="text search icon" />
                            </button>
                        </div>
                        <div
                            className="content-crums"
                        >
                            {
                                blogData && blogData.path.map(crum => (
                                    <>
                                        <span>·</span>
                                        <span className="crum">{crum}</span>
                                    </>
                                ))
                            }
                        </div>
                        <div
                            className="articles"
                        >
                            {
                                loadingBlogData ? (
                                    <div className="articles-loading">
                                        <Loading />
                                    </div>
                                ) : (blogData && parseBlogData())
                            }
                        </div>
                    </div>
                </div>
            </main>
            <section className='contact_section' ref={contactSectionRef}>
                <ContactSection />
            </section>
            <FooterSection />
        </>
    );
}

export default BlogPage;
