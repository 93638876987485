import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { SERVICES_LIST_CONTENT } from "../../../data/services";
import { ServiceItem } from "../../../pages/services/common";
import { AppContext } from "../../../context";
import { useTranslation } from "react-i18next";


const ServicesSection = () => {
    const { t } = useTranslation();
    const { clientEndpoints } = useContext(AppContext);

    const [servicesData, setServicesData] = useState<ServiceItem[]>([]);

    useEffect(() => {
        /**
         * Setting services information from API
        */
        clientEndpoints?.getServicesInformation().then(
            data => {
                let servicesContent: ServiceItem[] = data.content;
                setServicesData(servicesContent);
            }
        )
    }, []);

    return (
        <div
            className="services_box"
        >
            <span
                className="services_title"
            >
                {t("Home.Services.Title")}
            </span>
            <div
                className="services_list"
            >
                {
                    servicesData.map(service => (
                        <div 
                            className="service"
                        >
                            <span className="service_title">{service.title}</span>
                            <span className="service_description">{service.description}</span>
                            <div className="service_icon">
                                <img src={service.icon.empty} alt="service_icon_image_empty" />
                                <img src={service.icon.full} alt="service_icon_image_full" />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ServicesSection;
