import React, { useEffect, useRef } from "react";
import "./index.scss";
import HeaderSection from "../../components/sections/header";
import FooterSection from "../../components/sections/footer";
import { useTranslation } from "react-i18next";


const NotFoundPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <HeaderSection/>
            <main>
                <section className="not_found_section">
                    <div className="message">
                        <div className="logo">
                            <img src="/logo/white.png" alt="logo_not_found_image" />
                        </div>
                        {t("NotFound.Message")}
                    </div>
                </section>
            </main>
            <FooterSection />
        </>
    );
};

export default NotFoundPage;
