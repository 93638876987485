import React from "react";
import "./index.scss";

export enum BlockPosition {
    LEFT = "LEFT",
    CENTER = "CENTER",
    RIGHT = "RIGHT"
}


const Block = ({ children, position}: { children: any, position?: BlockPosition}) => {

    const getPositionClass = () => {
        let result;
        switch (position) {
            case BlockPosition.CENTER:
                result = "block_center"
                break;
            case BlockPosition.RIGHT:
                result = "block_right";
                break;
            case BlockPosition.LEFT:
                result = "block";
                break;
            default:
                result = "block";
                break;
        }

        return result;
    }

    return (
        <div
            className={getPositionClass()}
        >
            {children}
        </div>
    );
}

export default Block;
