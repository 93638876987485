

const getAmountSign = (amount: number) => {
    let result = "";

    switch (amount) {
        case 1000**0:
            result = ""
            break;
        case 1000**1:
            result = "k"
            break;
        case 1000**2:
            result = "M"
            break;
        case 1000**3:
            result = "B"
            break;
        case 1000**4:
            result = "T"
            break;
        default:
            result = "L";
            break;
    }

    return result;
}

export const getShortlengthNumber = (num: number) => {
    let divider = 1;
    let stop = false;

    while (!stop) {
        if (num/(divider*1000) >= 1) {
            divider = divider * 1000;
        } else {
            stop = true;
        }
    }

    return `${Math.floor(num/divider)}${getAmountSign(divider)}`;
}
