import { PriceParam } from "../pages/pricing/types";

export const PRICES_PARAM_QUESTIONS = [
    {
        id: "param_1",
        title: "Complejidad",
        question: [
            {
                value: "¿Que tan",
                type: "default"
            },
            {
                value: "Complejo",
                type: "keyword"
            },
            {
                value: "es tu proyecto?",
                type: "default"
            }
        ],
        options: {
            values: ["1","2","3","4","5","6","7","8","9"],
            tool: "dragger"
        }
    },
    {
        id: "param_2",
        title: "Tamaño",
        question: [
            {
                value: "¿Cual es el",
                type: "default"
            },
            {
                value: "Tamaño",
                type: "keyword"
            },
            {
                value: "de tu proyecto?",
                type: "default"
            }
        ],
        options: {
            values: ["pequeño", "mediano", "grande"],
            tool: "dragger"
        }
    },
    {
        id: "param_3",
        title: "Tiempo",
        question: [
            {
                value: "¿En cuanto",
                type: "default"
            },
            {
                value: "Tiempo",
                type: "keyword"
            },
            {
                value: "necesitas listo tu proyecto?",
                type: "default"
            }
        ],
        options: {
            values: ["1 sem", "1 mes", "6 mes", "1 año"],
            tool: "dragger"
        }
    },
    {
        id: "param_4",
        title: "Industria",
        question: [
            {
                value: "¿A que",
                type: "default"
            },
            {
                value: "Industria",
                type: "keyword"
            },
            {
                value: "pertenece tu proyecto?",
                type: "default"
            }
        ],
        options: {
            values: ["Salud", "Technologia", "Financias", "Legal", "Ventas"],
            tool: "selector"
        }
    }
]

export const DEFAULT_PRICE_ESTIMATE = (t: (text: string) => string) => ({
    concept: {
        title: t("Prices.Concept.Title"),
        description: t("Prices.Concept.Description")
    },
    currency: "COP"
});


export const MOCK_PRICE_ESTIMATE = {
    concept: {
        title: "Costo Estimado",
        description: "Información relacionada con el costo"
    },
    value: {
        min: 123412,
        max: 893597234
    },
    currency: "COP"
};
