import React from "react";
import "./index.scss";
import Block, { BlockPosition } from "../block";


const VideoBlock = ({ name, source, size, position }: { name: string, source: string, size: { w: number, h: number }, position?: BlockPosition }) => {
    return (
        <Block position={position}>
            <iframe
                width={size.w}
                height={size.h}
                src={source}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={name}
                className="video-frame"
            />
        </Block>
    );
}

export default VideoBlock;
