
export const MENU_PAGE_SERVICE_ID = "services"
export const MENU_PAGE_PRICES_ID = "prices"
export const MENU_PAGE_US_ID = "us"
export const MENU_PAGE_BLOG_ID = "blog"
export const MENU_PAGE_CARRERS_ID = "careers"

export const MENU = (t: (path: string) => string, lang: string) => [
    {id: MENU_PAGE_SERVICE_ID, name: t("Header.Menu.Services"), link: `/${lang}/servicios?service=api`, items: [1]},
    {id: MENU_PAGE_PRICES_ID, name:  t("Header.Menu.Prices"), link: `/${lang}/precios`},
    {id: MENU_PAGE_US_ID, name:  t("Header.Menu.Us"), link: `/${lang}/nosotros`},
    {id: MENU_PAGE_BLOG_ID, name:  t("Header.Menu.Blog"), link: `/${lang}/blog`},
    {id: MENU_PAGE_CARRERS_ID, name:  t("Header.Menu.Careers"), link: `/${lang}/carreras`}
]
