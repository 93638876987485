import React, { useContext } from "react";
import "./index.scss";
import { AppContext } from "../../../context";


export enum HamburguerStyle {
    DEFAULT,
    DARK
}

const Hamburguer = ({style}:{style?: HamburguerStyle}) => {
    const { setShowScreenMenu } = useContext(AppContext);

    const hamburguerClass = () => {
        let result = "hamburguer";

        switch (style) {
            case HamburguerStyle.DARK:
                result = "hamburguer_dark";
                break;
        }

        return result;
    }

    return (
        <div 
            className={hamburguerClass()}
            onClick={() => setShowScreenMenu(true)}
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Hamburguer;
