import React, { useEffect, useRef, useState } from 'react';
import "./index.scss";

import HeaderSection from '../../components/sections/header';
import VideoSection from '../../components/sections/video';
import ValuesSection from '../../components/sections/values';
import ServicesSection from '../../components/sections/services';
import ContactSection from '../../components/sections/contact';
import FooterSection from '../../components/sections/footer';


const HomePage = () => {

    const contactSectionRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);
  
    return (
      <>
        <HeaderSection onContactClick={() => contactSectionRef.current?.scrollIntoView()}/>
        <main>
          <section
            className='video_section'
          >
            <VideoSection />
          </section>
          <section
            className='values_section'
          >
            <ValuesSection />
          </section>
          <section
            className='services_section'
          >
            <ServicesSection />
          </section>
          <section className='contact_section' ref={contactSectionRef}>
            <ContactSection />
          </section>
        </main>
        <FooterSection />
      </>
    );
  }

export default HomePage;
