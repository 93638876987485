import React, { useEffect, useRef } from "react";
import "./index.scss";
import HeaderSection from "../../components/sections/header";
import FooterSection from "../../components/sections/footer";
import ContactSection from "../../components/sections/contact";
import { HeaderStyle } from "../../components/sections/header/types";
import VideoTool from "../../components/video";
import { useTranslation } from "react-i18next";


const UsPage = () => {
    const { t } = useTranslation();
    const contactSectionRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <HeaderSection 
                onContactClick={() => contactSectionRef.current?.scrollIntoView()}
                style={HeaderStyle.DARK}
                keepStyle={true}
            />
            <main>
                <section className="us_section">
                    <div className="us_title">
                        <span className="main_title">{t("Us.Title")}</span>
                        <span className="sub_title">{t("Us.Subtitle")}</span>
                    </div>
                    <VideoTool
                        posterSrc="https://assets.vitessestack.com/website/promotional_thumbnail.png"
                        videoSrc="https://assets.vitessestack.com/website/promotional_sound.mp4"
                    />
                    <div className="content_one">
                        <div className="left_content">
                            <span className="info_one">
                                <h3>{t("Us.Message.Title")}</h3>
                                <p>{t("Us.Message.Content")}</p>
                            </span>
                        </div>
                        <div className="right_content">
                            <div className="globe">
                                <div className="quote">
                                    <span>"</span> {t("Us.Quote.Message")} <span>"</span>
                                </div>
                                <span className="quote_person">{t("Us.Quote.Author")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="content_two">
                        <span className="title">{t("Us.Differenciators.Title")}</span>
                        <div className="differenciators">
                            <div className="value">
                                <div className="icon">
                                    <img src="/icons/values/diamond.png" alt="differentiator_value" />
                                </div>
                                <h3>{t("Us.Differenciators.Content.1.Title")}</h3>
                                <p>{t("Us.Differenciators.Content.1.Message")}</p>
                            </div>
                            <div className="value">
                                <div className="icon">
                                    <img src="/icons/values/motivation.png" alt="differentiator_value" />
                                </div>
                                <h3>{t("Us.Differenciators.Content.2.Title")}</h3>
                                <p>{t("Us.Differenciators.Content.2.Message")}</p>
                            </div>
                            <div className="value">
                                <div className="icon">
                                    <img src="/icons/values/handshake.png" alt="differentiator_value" />
                                </div>
                                <h3>{t("Us.Differenciators.Content.3.Title")}</h3>
                                <p>{t("Us.Differenciators.Content.3.Message")}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='contact_section' ref={contactSectionRef}>
                    <ContactSection />
                </section>
            </main>
            <FooterSection />
        </>
    );
};

export default UsPage;
