import { DEFAULT_LANGUAGE } from "../constants";
import { HOST } from "../data/client";
import { request, requestNoCache } from "./base";


export type APIRequestResponse = {
    data?: string;
    content?: any;
    response?: any;
}

export const getClientEndpoints = (
    setCache?: (key: string, value: string, ms: number) => void,
    getCache?: (key: string) => any | null,
    language?: string
) => {
    
    const getBodyWithLanguage = (body: any) => {
        return {
            ...body,
            language: language || DEFAULT_LANGUAGE
        }
    }

    const get = (path: string, noCache?: boolean): Promise<any> => {
        return noCache ? 
            requestNoCache(path, "GET") :
            request(path, "GET", undefined, setCache, getCache);

    }
    
    const post = (path: string, body: any, noCache?: boolean): Promise<any> => {
        return noCache ? 
            requestNoCache(path, "POST", getBodyWithLanguage(body)) :
            request(path, "POST", getBodyWithLanguage(body), setCache, getCache);
    }

    const getPricesInformation = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "prices"
        });
    }
    
    const getValuesInformation = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "values"
        });
    }
    
    const getServicesInformation = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "services"
        });
    }

    const getAppointmentSchedule = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "appointment"
        });
    }

    const getProductEmail = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "product_email"
        });
    }

    const postCommunication = (data: any): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/communication`;
        return post(path, data, true);
    }

    const getEstimation = (data: any): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/estimate`;
        return post(path, data, true);
    }

    const getBrochureLink = (): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/data`;
        return post(path, {
            "data_type": "brochure"
        });
    }

    const getBlogs = (filters: any): Promise<APIRequestResponse> => {
        const path = `${HOST.API}/blog`;
        return post(path, filters);
    }


    return {
        getPricesInformation,
        getValuesInformation,
        getServicesInformation,
        getAppointmentSchedule,
        getProductEmail,
        postCommunication,
        getEstimation,
        getBrochureLink,
        getBlogs
    }
}
