import Block, { BlockPosition } from "./block";
import TextBlock, { TextStyle } from "./text";
import ImageBlock from "./image";
import VideoBlock from "./video";
import CodeBlock from "./code";

export enum BlockType {
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    CODE = "CODE"
}

export type BlockPayload = {
    blockType: string;
    data: {
        position?: string;
        text?: string;
        textStyle?: string;
        imageSource?: string;
        imageName?: string;
        imageWidth?: number;
        videoSource?: string;
        videoName?: string;
        videoWidth?: number;
        videoHeigth?: number;
        codeString?: string;
        codeLanguage?: string;
        codeName?: string;
        codeLink?: string;
        codeTerminal?: boolean;
    };
}


export const getBlocksFromPayload = (payloads: BlockPayload[]) => {
    return payloads.map(payload => {
        let block;
        let position = payload.data.position ? BlockPosition[payload.data.position as keyof typeof BlockPosition] : undefined;

        switch (payload.blockType) {
            case BlockType.TEXT:
                block = <TextBlock 
                    text={payload.data.text!} 
                    textStyle={TextStyle[payload.data.textStyle as keyof typeof TextStyle]}
                    position={position}
                />
                break;
            case BlockType.IMAGE:
                block = <ImageBlock 
                    source={payload.data.imageSource!}
                    name={payload.data.imageName!}
                    size={payload.data.imageWidth!}
                    position={position}
                />
                break;
            case BlockType.VIDEO:
                block = <VideoBlock
                    name={payload.data.videoName!}
                    source={payload.data.videoSource!}
                    size={{
                        w: payload.data.videoWidth!,
                        h: payload.data.videoHeigth!
                    }}
                    position={position}
                />
                break;
            case BlockType.CODE:
                block = <CodeBlock
                    code={payload.data.codeString!}
                    language={payload.data.codeLanguage!}
                    name={payload.data.codeName!}
                    link={payload.data.codeLink}
                    position={position}
                    terminal={payload.data.codeTerminal}
                />
                break;
        }

        return block;
    })
}

export {
    Block,
    TextBlock,
    ImageBlock,
    VideoBlock,
    CodeBlock
}
