import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import { VALUES_DETAILS } from "../../../data";
import { AppContext } from "../../../context";
import { useTranslation } from "react-i18next";


export enum ValueContentType {
    DEFAULT = "default",
    HIGHLIGHT = "highlight"
}

export type ValueValue = {
    id: string;
    title: string;
    content: {
        text: string;
        type: string;
    }[];
    icon: {
        default: string;
        selected: string;
    }
}


const ValuesSection = () => {
    const { t } = useTranslation();
    const { clientEndpoints } = useContext(AppContext);

    const [values, setValues] = useState<ValueValue[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [showValueContent, setShowValueContent] = useState<boolean>(false);
    const timeoutIdRef = useRef<any>(null);

    useEffect(() => {
        /**
         * Setting values information from API
        */
        clientEndpoints?.getValuesInformation().then(
        data => {
            let content: ValueValue[] = data.content;
            setValues(content);
        }
       )
    }, []);

    const selectOnHover = (key: string) => {
        clearTimeout(timeoutIdRef.current);
        setSelectedValue(key);
        setShowValueContent(true);
        timeoutIdRef.current = setTimeout(() => {
            setShowValueContent(false);
            setSelectedValue("");
        }, 5000);
    }

    const textStyle = (type: string) => {
        let result = "value_value_content_default";

        switch (type) {
            case ValueContentType.HIGHLIGHT:
                result = "value_value_content_highlight"
                break;
        }

        return result;
    }

    return (
        <div className="values_box">
            <section className="values_mission_values">
                <section className="values_mission">
                    <span className="values_mission_title">
                        {t("Home.Values.Title")}
                    </span>
                    <section className="values_values">
                        <div
                            className="values_values_icons"
                        >
                            {
                                values.map(value => (
                                    <div className="values_value">
                                        <span className="value_value_title">{value.title}</span>
                                        <div
                                            className="values_value_ball" 
                                            onMouseOver={() => selectOnHover(value.id)}
                                        >
                                            <img src={value.icon.default} alt="values_value_vall_icon_image_default" />
                                            <img src={value.icon.selected} alt="values_value_vall_icon_image_selected" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    {/* <span className="values_mission_content">Nuestra misión es brindar soluciones innovadoras y personalizadas que optimicen los procesos y mejoren la productividad de su negocio. Nos enfocamos en construir software robusto, escalable y seguro, utilizando las últimas tecnologías y metodologías de desarrollo.</span> */}
                </section> 
            </section>
            <section className="values_values_box">
                <section className={showValueContent ? "selected_value": "selected_value_hidden"}>
                    <div className="selected_value_bg">
                        <div className="selected_value_icon">
                            <img src="/logo/white.png" alt="" />
                        </div>
                    </div>
                    <div className="selected_value_items">
                        {
                            values.map(value => 
                                selectedValue == value.id ? (
                                <span className="value_value_content">
                                    {
                                        value.content.map(text => (
                                            <>
                                                <span
                                                    className={textStyle(text.type)}
                                                >{text.text}</span>
                                                <span> </span>
                                            </>
                                        ))
                                    }
                                </span>
                            ) : null)
                        }
                    </div>
                </section>
            </section>
        </div>
    );
};

export default ValuesSection;
