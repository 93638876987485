import React, { useEffect } from "react";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { MENU } from "../../data/menu";
import { MenuStyle, MenuOption } from "./types";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../constants";

const Menu = ({style, selectedOption}:{style?: MenuStyle, selectedOption?: string}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { lang } = useParams();

    const menuClass = () => {
        let result = "menu";

        switch (style) {
            case MenuStyle.DARK:
                result = "menu_dark";
                break;
        }

        return result;
    }

    return (
        <menu className={menuClass()}>
            {
                (MENU(t, (lang as string).toLowerCase() || DEFAULT_LANGUAGE) as MenuOption[]).map(option => (
                    <button
                        onClick={() => navigate(option.link)}
                    >
                        <span
                            className={selectedOption == option.id ? "menu_option_selected" : "menu_option"}
                        >{option.name}</span>
                        {
                            !(option.items && option.items?.length > 0) ? null : (
                                <span></span>
                            )
                        }
                    </button>
                ))
            }
        </menu>
    );
};


export default Menu;
