import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import HomePage from './pages/home';
import ServicesPage from './pages/services';
import PricingPage from './pages/pricing';
import UsPage from './pages/us';
import CareersPage from './pages/careers';
import BlogPage from './pages/blog';
import NotFoundPage from './pages/notfound';
import ConfigurationPage from './pages/conf';
import { DEFAULT_LANGUAGE } from './constants';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/${DEFAULT_LANGUAGE}`} replace />} />
      <Route path="/:lang" element={<ConfigurationPage />}>
        <Route path="/:lang" element={<HomePage />} />
        <Route path="/:lang/servicios" element={<ServicesPage />} />
        <Route path="/:lang/precios" element={<PricingPage />} />
        <Route path="/:lang/nosotros" element={<UsPage />} />
        <Route path="/:lang/blog" element={<BlogPage />} />
        <Route path="/:lang/carreras" element={<CareersPage />} />
      </Route>
      <Route path='*' element={<NotFoundPage />}/>
    </Routes>
  );
}

export default App;
