import React, { useEffect, useState } from "react";
import "./index.scss";

import { ServiceItem } from "../../common";


const PhasesServicesSection = ({data}:{data: ServiceItem}) => {
    return (
        <section className="service_phases">
            <span className="service_phases_title">
                Fases
            </span>
            <div className="service_phases_box">
                <div className="service_phases_phases">
                {
                    data.content?.phases.map(
                        (phase, index) => (
                            <div className="service_phases_phase">
                                <span className="service_phases_phase_number">{index+1}</span>
                                <div className="service_phases_phase_content">
                                    <span>{phase.title}</span>
                                    <div className="phase_items">
                                        {
                                            phase.items.map(item => (
                                                <div className="phase_item">
                                                    <span>
                                                        {item.title}
                                                    </span>
                                                    <span>{item.description}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                </div>
            </div>

        </section>
    );
}

export default PhasesServicesSection;
