import React, { useEffect, useRef } from "react";
import "./index.scss";
import HeaderSection from "../../components/sections/header";
import FooterSection from "../../components/sections/footer";
import { HeaderStyle } from "../../components/sections/header/types";
import { useTranslation } from "react-i18next";


const CareersPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        // Scroll To top on load
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <HeaderSection/>
            <main>
                <section className="careers_section">
                    <div className="message">
                        <div className="logo">
                            <img src="/logo.png" alt="logo_careers_image" />
                        </div>
                        {t("Careers.Unavailable")}
                    </div>
                </section>
            </main>
            <FooterSection />
        </>
    );
};

export default CareersPage;
