import React, { useEffect, useRef, useState } from "react";
import "./index.scss";


const VideoTool = ({posterSrc, videoSrc}:{posterSrc: string, videoSrc: string}) => {
    const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
    const [videoFinished, setVideoFinished] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const togglePlay = () => {
        if (videoFinished) {
            setVideoFinished(false);
            videoRef.current?.play();
            setVideoPlaying(true);
        } else {
            if (videoPlaying) {
                videoRef.current?.pause();
                setVideoPlaying(false);
            } else {
                videoRef.current?.play();
                setVideoPlaying(true);
            }
        }
    }

    useEffect(() => {
        if (videoFinished) {
            setVideoPlaying(false);
        }
    }, [videoFinished]);

    return (
        <div
            className="video_tool"
        >
            <video
                ref={videoRef}
                poster={posterSrc}
                src={videoSrc}
                onEnded={() => setVideoFinished(true)}
            >
            </video>
            <div className={videoPlaying ? "video_overlay_playing" : "video_overlay"}>
                <button
                    onClick={togglePlay}
                >
                    <img 
                        src={videoFinished ? "/icons/various/reload.png" : (videoPlaying ? "/icons/various/pause.png" : "/icons/various/play.png")}
                        alt="video_play_image"
                    />
                </button>
            </div>
        </div>
    )
}


export default VideoTool;
