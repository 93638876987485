import React from "react";
import "./index.scss";
import { ServiceItem } from "../../common";


const DeliverablesServicesSection = ({data}:{data: ServiceItem}) => {
    return (
        <section className="service_deliverables">
            <span className="service_deliverables_title">
                Entregables
            </span>
            <div className="service_deliverables_box">
                <div className="service_deliverables_deliverables">
                {
                    data.content?.deliverables.map(
                        (deliverable, index) => (
                            <div className="service_deliverables_deliverable">
                                {
                                    deliverable.image && (
                                        <div className="service_deliverables_deliverable_image">
                                            <img src={deliverable.image.default} alt="" />
                                            <img src={deliverable.image.full} alt="" />
                                        </div>
                                    )
                                }
                                <div className="service_deliverables_deliverable_content">
                                    <span>{deliverable.title}</span>
                                    <div className="deliverable_items">
                                        {
                                            deliverable.items.map(item => (
                                                <div className="deliverable_item">
                                                    <span>
                                                        {item.title}
                                                    </span>
                                                    <span>{item.description}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                </div>
            </div>
        </section>
    );
}

export default DeliverablesServicesSection;
