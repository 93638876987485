
import { createContext } from 'react';
import { APIRequestResponse } from './client';

type AppContextType = {
  showScreenMenu: boolean;
  setShowScreenMenu: (val: boolean) => void;
  getCacheValue: (key: string) => string | null;
  setCacheValue: (key: string, val: string) => void;
  clientEndpoints?: {
    getPricesInformation: () => Promise<APIRequestResponse>,
    getValuesInformation: () => Promise<APIRequestResponse>,
    getServicesInformation: () => Promise<APIRequestResponse>,
    getAppointmentSchedule: () => Promise<APIRequestResponse>,
    getProductEmail: () => Promise<APIRequestResponse>,
    postCommunication: (data: any) => Promise<APIRequestResponse>,
    getEstimation: (data: any) => Promise<APIRequestResponse>,
    getBrochureLink: () => Promise<APIRequestResponse>,
    getBlogs: (filters: any) =>Promise<APIRequestResponse>
  };
}

export const AppContext = createContext<AppContextType>({
  showScreenMenu: false,
  setShowScreenMenu: (val: boolean) => {},
  getCacheValue: () => null,
  setCacheValue: () => {}
});
