import React from "react";
import "./index.scss";
import Block, { BlockPosition } from "../block";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight, cb } from 'react-syntax-highlighter/dist/esm/styles/prism';


const CodeBlock = ({ code, language, name, link, position, terminal }: { code: string, language?: string, name?: string, link?: string, position?: BlockPosition, terminal?: boolean }) => {

    const customStyle = {
        fontSize: '15px',
        padding: `${terminal ? 10 : 30}px 0px 10px ${terminal ? 30 : 0}px`,
        margin: "0",
        borderRadius: "0"
    };
    
    return (
        <Block position={position}>
            <div
                className="code-window"
            >
                {
                    terminal ? null : (
                        <>
                            <div
                                className="code-window-bar"
                            >
                                <span className="code-name">{name}</span>
                                {
                                    link != undefined ? (
                                        <a href={link} target="_blank" className="code-link">
                                            <img src="/icons/various/reload.png" alt="Go to link icon" />
                                        </a>
                                    ) : null
                                }
                            </div>
                            {/* <div className="code-bar-separator"></div> */}
                        </>
                    )
                }
                <div
                    className="code"
                >
                    <SyntaxHighlighter 
                        language={terminal ? "bash": language}
                        style={terminal ? cb: oneLight}
                        customStyle={customStyle}
                        showLineNumbers={!terminal}
                        wrapLines
                    >
                        {code}
                    </SyntaxHighlighter>
                </div>
            </div>
        </Block>
    );
}

export default CodeBlock;
