import React from "react";
import "./index.scss";

import { ServiceItem } from "../../common";


const WhatIsServicesSection = ({data}:{data?: ServiceItem}) => {
    return (
        <section className="service_what_is">
            <span className="service_what_is_title">
                {data?.content?.what_is.title}
            </span>
            <div className="service_what_is_fragments">
                {
                    data?.content?.what_is.fragments.map(
                        fragment => (
                            <span>
                                {
                                    fragment.split(" ").map(
                                        word => <span> {word}</span>
                                    )
                                }
                            </span>
                        )
                    )
                }
            </div>
        </section>
    );
}

export default WhatIsServicesSection;
